<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('projects.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.client_id"
              dense
              outlined
              :label="t('projects.client')"
              :items="clientOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.project_type"
              dense
              outlined
              :label="t('projects.construction_site_type')"
              :items="projectTypeOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="dataComputed.note"
              label="Description"
              outlined
              rows="2"
              :rules="[]"
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { getClientsActive } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import useOptions from '@core/utils/useOptions'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { statusOptions, projectTypeOptions } = useOptions()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const dataAcount = ref({})
    const initialData = {
      name: null,
      note: null,
      playing_time: null,
      status: null,
    }
    const clientOptions = ref([])

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        note: null,
        playing_time: null,
        status: null,
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    onMounted(async () => {
      const response = await getClientsActive()
      if (response.ok) clientOptions.value = response.data
    })

    return {
      // data
      form,
      dataAcount,
      initialData,
      clientOptions,

      // computed
      dataComputed,
      statusOptions,
      projectTypeOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,

      // validations
      required,
      emailValidator,
      integerValidator,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
